import AbstractOnboardResource from "@/resources/abstract/AbstractOnboardResource";
import ProposalFactory from "@/models/factories/ProposalFactory";
import { EMessageSendingType } from "@/constants/MessageSendingTypes";

export default class ProposalResource extends AbstractOnboardResource {
  /**
   *
   * @param {{}}object
   * @param object.covenantId
   * @param object.roleId
   * @param object.requiredInstallments
   * @param object.requiredValue
   * @param object.loanReasonId
   * @param object.loanTypeId
   * @param object.loanCategoryId
   * @param object.modalityId
   * @param object.shouldQueue
   * @param object.additionalData
   * @return {Promise<ResourceReturnModel>}
   */
  async create({
    covenantId,
    roleId,
    requiredInstallments,
    requiredValue,
    loanReasonId,
    loanTypeId,
    modalityId,
    loanCategoryId,
    shouldQueue = true,
    required_installment_value,
    nominal_cet_month,
    nominal_rate_month,
    cnpjAssignee,
    disbursementDate,
    firstPaymentDate,
    additionalData,
    bank_loan_configuration_id,
  }) {
    try {
      const response = await this.api.post({
        route: "/proposals",
        shouldQueue,
        payload: {
          bank_loan_configuration_id,
          covenant_id: covenantId,
          role_id: roleId,
          required_installments: requiredInstallments,
          required_value: requiredValue,
          loan_reason_id: loanReasonId,
          loan_type_id: loanTypeId,
          modality_id: modalityId,
          loan_category_id: loanCategoryId,
          required_installment_value,
          nominal_cet_month,
          nominal_rate_month,
          cnpj_assignee: cnpjAssignee,
          deposit_date: disbursementDate,
          first_payment_date: firstPaymentDate,
          additional_data: additionalData,
        },
      });

      const factorizedProposal = ProposalFactory.create(response?.data?.response || {});

      return this.resourceReturnFactory.create({
        object: {
          data: factorizedProposal,
          responseObject: response,
          error: false,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          data: ProposalFactory.create(),
          responseObject: e,
          error: true,
        },
      });
    }
  }

  /**
   * @description Lista as propostas de acordo com as informações de páginação
   * @param {{}}object
   * @param {boolean} object.shouldQueue - Página das propostas
   * @param {string | number} object.page - Página das propostas
   * @param {string | number} object.length - Quantas propostas por página
   * @param {string} object.includes - Informações adicionais para vir junto das propostas
   * @param {Object} object.params - Parâmetros adicionais
   * @return {Promise<ResourceReturnModel>}
   */
  async getProposals({ shouldQueue = true, page, length, includes = "", ...params }) {
    try {
      const response = await this.api.get({
        params: {
          page,
          length,
          includes,
          ...params,
        },
        route: "/proposals",
        shouldQueue,
      });

      return this.resourceReturnFactory.create({
        object: {
          error: false,
          data: response?.data?.response?.rows || [],
          count: response?.data?.response?.count || 0,
          responseObject: response,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          data: [],
          responseObject: e,
        },
      });
    }
  }

  /**
   * @param {{}}object
   * @param {number} object.proposalId
   * @param {string} object.includes
   * @return {Promise<ResourceReturnModel>}
   */
  async getProposal({ proposalId, includes = "" }) {
    try {
      const response = await this.api.get({
        route: `/proposals/${proposalId}`,
        params: { includes },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          data: response?.data?.response || {},
          responseObject: response,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          data: {},
          responseObject: e,
        },
      });
    }
  }

  async ciadocreditoProposal(cpf) {
    try {
      const response = await this.api.post({ route: "/proposal-ciadocredito", payload: { cpf } });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: e,
        },
      });
    }
  }

  async getSummary(proposalId) {
    try {
      const response = await this.api.get({ route: `/proposal/${proposalId}/summary` });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: e,
        },
      });
    }
  }

  /**
   *
   * @param proposalId
   * @param payload
   * @return {Promise<ResourceReturnModel>}
   */
  async updateProposal({ proposalId, ...payload }) {
    try {
      const response = await this.api.put({
        route: `/proposals/${proposalId}`,
        payload,
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  async cancelProposal(proposalId = 0) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/status/cancel`,
      });
      return this.newResourceReturnFactory.create({ response, data: response.data.response || {} });
    } catch (e) {
      return this.newResourceReturnFactory.create({
        response: e,
        data: {},
      });
    }
  }

  async cancelProposalById(proposalId) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/status/cancel-by-borrower`,
      });
      return this.newResourceReturnFactory.create({ response, data: response.data.response || {} });
    } catch (e) {
      return this.newResourceReturnFactory.create({
        response: e,
        data: {},
      });
    }
  }

  /**
   *
   * @param proposalId
   * @return {Promise<ResourceReturnModel>}
   */
  async proposalValuesConfirmation({ proposalId }) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/values-confirmation`,
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  async geolocationConfirmValuesCompliance({ proposalId, lat, long, code, message }) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/geolocation`,
        payload: { lat, long, code, message },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  async contractSummaryConfirmation({ proposalId }) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/contract-summary-confirmation`,
      });

      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  /**
   * @param {number} props.bank_loan_configuration_id
   * @param {number} props.roleId
   * @param {number} props.covenantId
   * @param props.requiredInstallments
   * @param props.requiredValue
   * @param {number} props.loanTypeId
   * @param {number} props.userId
   * @param {number} props.modalityId
   * @param {number} props.loanCategoryId
   * @return {Promise<{error:boolean, data: any, responseObject:{}}>}
   */
  async adminCreate(props) {
    try {
      const response = await this.api.post({
        route: `/users/${props.userId}/proposals`,
        payload: {
          bank_loan_configuration_id: props?.bank_loan_configuration_id,
          covenant_id: props?.covenantId,
          loan_type_id: props?.loanTypeId,
          required_installments: props?.requiredInstallments,
          required_value: props?.requiredValue,
          role_id: props?.roleId,
          modality_id: props?.modalityId,
          loan_category_id: props?.loanCategoryId,
        },
      });
      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || {},
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: {},
        },
      });
    }
  }

  /**
   *
   * @param proposalId
   * @return {Promise<{error:boolean, data: [], responseObject:{}}>}
   */
  async getProposalFlow({ proposalId }) {
    try {
      const response = await this.api.get({ route: `/proposals/${proposalId}/status-flow` });

      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || [],
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: [],
        },
      });
    }
  }

  async resendEmailWithContract(proposalId = 0, messageSendingType = EMessageSendingType.Email) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/messages/resend-contract`,
        payload: {
          message_sending_type: messageSendingType,
        },
      });
      return this.newResourceReturnFactory.create({
        response,
        data: response.data?.response || {},
      });
    } catch (e) {
      return this.newResourceReturnFactory.create({
        response: e,
        data: {},
      });
    }
  }

  async calculateArbiLimitValues({ proposalId, availableBalanceAmount }) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/arbi-limit-values`,
        payload: {
          available_balance_amount: availableBalanceAmount,
        },
      });
      return this.newResourceReturnFactory.create({
        response,
        data: response.data?.response || {},
      });
    } catch (e) {
      return this.newResourceReturnFactory.create({
        response: e,
        data: {},
      });
    }
  }

  async getArbiLimitValues({ proposalId }) {
    try {
      const response = await this.api.get({
        route: `/proposals/${proposalId}/arbi-limit-values`,
      });
      return this.newResourceReturnFactory.create({
        response,
        data: response.data?.response || {},
      });
    } catch (e) {
      return this.newResourceReturnFactory.create({
        response: e,
        data: {},
      });
    }
  }

  async simulateFGTSProposal({ proposalId, requiredValue }) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/simulate-fgts`,
        payload: {
          required_value: requiredValue,
        },
      });
      return this.newResourceReturnFactory.create({
        response,
        data: response.data?.response || {},
      });
    } catch (e) {
      return this.newResourceReturnFactory.create({
        response: e,
        data: {},
      });
    }
  }

  async getProposalDetails(proposalId) {
    try {
      const response = await this.api.get({ route: `/proposals/${proposalId}/proposal-details` });

      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response || [],
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: [],
        },
      });
    }
  }

  /**
   *
   * @param proposalId
   * @return {Promise<{error:boolean, data: {id: string}, responseObject:{data: {message: string}}>}
   */
  async liveness(proposalId) {
    try {
      const response = await this.api.post({
        route: `/proposals/${proposalId}/livenessId`,
        params: {
          service: "BigData",
        },
      });

      return this.resourceReturnFactory.create({
        object: {
          error: false,
          responseObject: response,
          data: response?.data?.response,
        },
      });
    } catch (e) {
      return this.resourceReturnFactory.create({
        object: {
          error: true,
          responseObject: e,
          data: [],
        },
      });
    }
  }
}
