import { createTRPCClient, httpBatchLink } from "@trpc/client";
import type { AppRouter } from "@/types/trpc/index.d.mts";

let headers: Record<string, string> = {};

const setHeaders = (newHeaders: object) => {
  headers = { ...headers, ...newHeaders };
};
const setTokenFromLocalStorage = () => {
  const token = localStorage.getItem("Authorization");

  if (token) {
    headers = { ...headers, Authorization: token };
  }
};

const trpcClient = createTRPCClient<AppRouter>({
  links: [
    httpBatchLink({
      url: `${import.meta.env.VITE_VUE_APP_ONBOARD_URL}public/trpc`,
      headers: () => headers,
    }),
  ],
});

export const trpc = { client: trpcClient, setHeaders, setTokenFromLocalStorage };
