const getters = {
  isAdminUser: (state) => {
    const { groups = [] } = state.user;
    const foundAdminGroup = groups.find((item) => ["super_admin", "admin"].includes(item.code));

    return !!foundAdminGroup;
  },
  isRh(state) {
    const foundRhGroup = state.user?.groups?.find((item) => ["rh"].includes(item.code));
    return !!foundRhGroup;
  },
};

export default getters;
