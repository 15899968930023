import ProposalService from "@/business/proposal/ProposalService";

const getters = {
  statuses: (state, getters, rootState, rootGetters) => {
    return {
      transferencia_finalizada: {
        shortTitle: "Aprovado",
        componentName: "AwaitTransfer",
        code: "transferencia_finalizada",
        title: "Transferência finalizada",
        description: "Sua transferência foi finalizada",
      },
      documentacao_adicional: {
        code: "documentacao_adicional",
        shortTitle: "Status que envolvem o upload de documentos do Usuário",
        componentName: "ShippingDocumentation",
        title: "Documentação adicional",
        description: "Nesse momento precisamos de todos os seus documentos",
      },
      endereco_confirmacao: {
        componentName: "AddressConfirmation",
        shortTitle: "Endereço",
        code: "endereco_confirmacao",
        title: "Confirmação do endereço",
        description: "Por favor, preencha os campos abaixo para validarmos seu endereço",
      },
      documentacao_envio: {
        code: "documentacao_envio",
        shortTitle: "Status que envolvem o upload de documentos do Usuário",
        componentName:
          rootGetters.whiteLabel.partnerCode === "partner_medsimples"
            ? "Analyze"
            : "ShippingDocumentation",
        title: "Documentação",
        description: "Precisamos de alguns de seus documentos",
      },
      documentacao_envio_alternativo: {
        code: "documentacao_envio_alternativo",
        shortTitle: "Status que envolvem o upload de documentos do Usuário",
        componentName: "ShippingDocumentation",
        title: "Documentação",
        description: "Precisamos de alguns de seus documentos",
      },
      status_leitura_documento: {
        code: "status_leitura_documento",
        shortTitle: "Leitura dos documentos",
        componentName: "DocumentReading",
        title: "Leitura dos Documentos",
        description: "Leia atentamente os documentos",
      },
      status_assinatura_documento: {
        code: "status_assinatura_documento",
        shortTitle: "Assinatura do contrato",
        componentName: "DocumentSignature",
        title: "Assinatura",
        description: "Assine para confirmar que você leu e concorda com os contratos",
      },
      simulacao: {
        code: "simulacao",
        shortTitle: "Simulação",
        componentName: "ChooseBankingConfiguration",
        title: "Simulação da proposta",
        description: "Fique a vontade para simular",
      },
      referencia_pessoal: {
        componentName: "PersonalReferences",
        shortTitle: "Dados Básicos",
        code: "referencia_pessoal",
        title: "Referência pessoal",
        description: "Por favor preencha os campos abaixo para verificarmos suas referências",
      },
      questionario: {
        shortTitle: "Questionario",
        code: "questionario",
        componentName: "Quiz",
        title: "Perguntas para você",
        description: "Esse questionário foi gerado a partir de suas informações, tente ser coeso",
      },
      status_questionario_terceiro: {
        shortTitle: "Questionario",
        code: "status_questionario_terceiro",
        componentName: "Quiz",
        title: "Perguntas para você",
        description: "Esse questionário foi gerado a partir de suas informações, tente ser coeso",
      },
      transferencia_aguardando: {
        shortTitle: "Aprovado",
        componentName: "AwaitTransfer",
        code: "transferencia_aguardando",
        title: "Aprovado",
        description: "Sua proposta foi aprovada",
      },
      ppe: {
        componentName: "PoliticallyExposedPerson",
        shortTitle: "Dados Adicionais",
        code: "ppe",
        title: "Pessoa politicamente exposta",
        description: "Nos diga se você é ou é próximo de alguém politicamente exposto",
      },
      dados_bancarios: {
        code: "dados_bancarios",
        shortTitle: "Conta Bancária",
        componentName: "BankData",
        title: "Dados bancários",
        description:
          "Precisamos de algumas informações de sua conta para validarmos as informações",
      },
      sms_confirmacao: {
        code: "sms_confirmacao",
        shortTitle: "SMS",
        componentName: "SmsConfirmation",
        title: "Confirmação por SMS",
        description: "Enviaremos um código no seu número cadastrado",
      },
      contrato_refinanciamento: {
        code: "contrato_refinanciamento",
        shortTitle: "Contrato de Refinanciamento",
        componentName: "",
        title: "Contrato de refinanciamento",
        description:
          "Esse é o seu contrato de refinanciamento, você precisa assinar para continuar",
      },
      dados_adicionais_confirmacao: {
        componentName: "AdditionalDataConfirmation",
        shortTitle: "Confirmação de dados",
        code: "dados_adicionais_confirmacao",
        title: "Confirmação de dados",
        description: "Confirme alguns dados para validarmos",
      },
      dados_conjuge: {
        componentName: "SpouseInformation",
        shortTitle: "Dados do cônjuge",
        code: "dados_conjuge",
        title: "Confirmação de dados do cônjuge",
        description: "Confirme alguns dados do cônjuge para validarmos",
      },
      validacao_rh: {
        componentName: (function () {
          const isAdminUser = rootGetters["User/isAdminUser"];
          return isAdminUser ? "RhValidation" : "Analyze";
        })(),
        shortTitle: "Validação RH",
        code: "validacao_rh",
        title: "Validação dos dados do RH",
        description: "Confirme alguns dados do RH para validarmos",
      },
      analise_risco_2: {
        shortTitle: "Em Análise",
        componentName: "Analyze",
        code: "analise_risco_2",
        title: "",
        description: "",
      },
      status_resumo_contrato: {
        shortTitle: "Resumo do contrato",
        componentName: "ContractSummary",
        code: "status_resumo_contrato",
        title: "",
        description: "",
      },
      aguardando_aprovacao_banco: {
        shortTitle: "Em Análise",
        componentName: "Endorsement",
        code: "aguardando_aprovacao_banco",
        title: "",
        description: "",
      },
      assinatura: {
        componentName: "Contract",
        shortTitle: "Assinatura",
        code: "assinatura",
        title: "Contrato",
        description: "Assine o contrato da sua proposta para continuar",
      },
      analise_risco_c: {
        shortTitle: "Em Análise",
        componentName: "Analyze",
        code: "analise_risco_c",
        title: "",
        description: "",
      },
      valores_confirmacao: {
        code: "valores_confirmacao",
        shortTitle: "Confirmação",
        componentName: "ConfirmationValues",
        title: "Confirmação de valores",
        description: "Confirme os valores de sua proposta para continuar",
      },
      analise_risco: {
        shortTitle: "Em Análise",
        componentName: "Analyze",
        code: "analise_risco",
        title: "",
        description: "",
      },
      recusado: {
        componentName: "",
        code: "recusado",
        shortTitle: "Recusado",
      },
      analise: {
        shortTitle: "Em Análise",
        componentName: "Analyze",
        code: "analise",
        title: "",
        description: "",
      },
      status_analise_selfie: {
        shortTitle: "Análise de selfie",
        componentName: "SelfieAnalyze",
        code: "status_analise_selfie",
        title: "",
        description: "",
      },
      pre_analise_inicio: {
        componentName: "PreAnalysis",
        code: "pre_analise_inicio",
        shortTitle: "Grupos de Status que estão contidos até o envio do Holerite",
        title: "",
        description: "",
      },
      status_pre_analise_financiamento: {
        componentName: "PreAnalysis",
        code: "status_pre_analise_financiamento",
        shortTitle: "Grupos de Status que estão contidos até o envio do Holerite",
        title: "",
        description: "",
      },
      status_validacao_margens: {
        componentName: "Analyze",
        code: "status_validacao_margens",
        shortTitle: "",
        title: "",
        description: "",
      },
      status_regra_financeira: {
        componentName: "Analyze",
        code: "status_regra_financeira",
        shortTitle: "",
        title: "",
        description: "",
      },
      status_analise_risco_arbi_terceiro: {
        componentName: "Analyze",
        code: "status_analise_risco_arbi_terceiro",
        shortTitle: "",
        title: "",
        description: "",
      },
      status_segunda_analise_risco_arbi_terceiro: {
        componentName: "Analyze",
        code: "status_segunda_analise_risco_arbi_terceiro",
        shortTitle: "",
        title: "",
        description: "",
      },
      status_tabela_price: {
        componentName: "Analyze",
        code: "status_tabela_price",
        shortTitle: "",
        title: "",
        description: "",
      },
      averbacao: {
        shortTitle: "Averbação",
        componentName: "Endorsement",
        code: "averbacao",
        title: "",
        description: "Sua proposta está em averbação",
      },
      status_analise_risco_ip: {
        shortTitle: "Análise de risco de IP",
        componentName: "Endorsement",
        code: "status_analise_risco_ip",
        title: "",
        description: "Estamos analisando o risco do seu IP",
      },
      nao_atendido: {
        componentName: "",
        code: "nao_atendido",
        shortTitle: "Não atendido",
      },
      status_aceite_consentimentos: {
        componentName: "ConsentAccept",
        code: "status_aceite_consentimentos",
        shortTitle: "Não atendido",
      },
      email_confirmacao: {
        componentName: "EmailConfirmation",
        code: "email_confirmacao",
        shortTitle: "Confirmando email",
      },
      status_autorizacao_consulta_margem: {
        shortTitle: "Autorização consulta de margem",
        componentName: "MarginQueryAuthorization",
        code: "status_autorizacao_consulta_margem",
        title: "Autorização consulta de margem",
        description: "",
      },
      dados_ocupacao: {
        shortTitle: "Questionario",
        code: "dados_ocupacao",
        componentName: "OccupationData",
        title: "Perguntas para você",
        description: "Esse questionário foi gerado a partir de suas informações, tente ser coeso",
      },
      documentacao_envio_fiducia: {
        code: "documentacao_envio_fiducia",
        shortTitle: "Status que envolvem o upload de documentos do Usuário no fluxo da Fidúcia",
        componentName: "ShippingDocumentation",
        title: "Documentação",
        description: "Precisamos de alguns de seus documentos",
      },
      analise_risco_2_fiducia: {
        shortTitle: "Em Análise",
        componentName: "Analyze",
        code: "analise_risco_2_fiducia",
        title: "",
        description: "",
      },
      selfie_analysis_status: {
        code: "selfie_analysis_status",
        componentName: "Analyze",
        title: "",
        description: "",
      },
      status_analise_risco: {
        code: "status_analise_risco",
        componentName: "Analyze",
        title: "",
        description: "",
      },
      questionario_fiducia: {
        shortTitle: "Questionario",
        code: "questionario_fiducia",
        componentName: "Quiz",
        title: "Perguntas para você",
        description: "Esse questionário foi gerado a partir de suas informações, tente ser coeso",
      },
      consentimento_deposito_bancario: {
        shortTitle: "Questionario",
        code: "consentimento_deposito_bancario",
        componentName: "BankDepositConsent",
        title: "Perguntas para você",
        description: "Esse questionário foi gerado a partir de suas informações, tente ser coeso",
      },
      consolidacao_proposta: {
        shortTitle: "Em Análise",
        componentName: "Analyze",
        code: "consolidacao_proposta",
        title: "",
        description: "",
      },
      status_analise_de_credito_sincrono: {
        shortTitle: "Em Análise",
        componentName: "Analyze",
        code: "status_analise_de_credito_sincrono",
        title: "",
        description: "",
      },
      status_analise_credito_flow: {
        shortTitle: "Em Análise",
        componentName: "Analyze",
        code: "status_analise_credito_flow",
        title: "",
        description: "",
      },
      anexo_documentos_banco: {
        shortTitle: "Em Análise",
        componentName: "Analyze",
        code: "anexo_documentos_banco",
        title: "",
        description: "",
      },
      regra_financeira_fiducia: {
        shortTitle: "Em Análise",
        componentName: "FinancialRuleCDC",
        code: "regra_financeira_fiducia",
        title: "",
        description: "",
      },
      status_selfie_upload: {
        shortTitle: "Selfie upload",
        componentName: "ShippingDocumentation",
        code: "status_selfie_upload",
        title: "Selfie upload",
        description: "Selfie upload",
      },
      admin_user_select: {
        shortTitle: "Seleção do usuário da proposta",
        componentName: "AdminUserSelect",
        code: "admin_user_select",
        title: "Seleção do usuário da proposta",
      },

      // TODO STATUS NOVOS ADICIONADOS NA SPRINT 129
      status_simulacao_financiamento: {
        code: "status_simulacao_financiamento",
        shortTitle: "Simulação",
        componentName: "InternalSimulation",
        title: "Simulação da proposta",
        description: "Fique a vontade para simular",
      },
      status_aceite_termos_financiamento: {
        componentName: "ConsentAccept",
        code: "status_aceite_termos_financiamento",
        shortTitle: "Consentimento",
      },
      status_analise_risco_financiamento: {
        componentName: "Analyze",
        code: "status_analise_risco_financiamento",
        shortTitle: "Consentimento",
      },
      status_margens: {
        componentName: "Margins",
        code: "status_margens",
        shortTitle: "Margens",
      },
      status_lembrete_autorizacao_consulta: {
        componentName: "Reminder",
        code: "status_lembrete_autorizacao_consulta",
        shortTitle: "Lembrete",
      },
      status_dados_bancarios_terceiro: {
        code: "status_dados_bancarios_terceiro",
        componentName: "BankData",
        shortTitle: "Conta bancária terceiro",
        options: { skipFirstFlowOrderComponent: true },
      },
      status_analise_risco_c_terceiro: {
        code: "status_analise_risco_c_terceiro",
        componentName: "Analyze",
        shortTitle: "Análise de risco C",
      },
      status_analise_finalizada: {
        code: "status_analise_finalizada",
        componentName: "Analyze",
        shortTitle: "Análise finalizada",
      },
      send_contracts_status: {
        code: "send_contracts_status",
        componentName: "SendContract",
        shortTitle: "Envio de contratos",
      },
      // TODO DEBITO TECNICO
      status_envio_reserva_margem: {
        code: "status_envio_reserva_margem",
        componentName: rootGetters["User/isAdminUser"] ? "ShippingDocumentation" : "Endorsement",
        shortTitle: "Envio de reserva de margem",
      },

      // STATUS INSERIDO NO FLUXO FGTS
      status_confirmacao_valores_simulados: {
        code: "status_confirmacao_valores_simulados",
        componentName: "SimulationFGTS",
      },

      status_prova_de_vida: {
        code: "status_prova_de_vida",
        componentName: "FacialRecognition",
      },

      // MOTOROLA
      status_simulacao_financiamento_motorola: {
        code: "status_simulacao_financiamento_motorola",
        componentName: "SimulateMotorola",
      },

      status_cadastro_venda_motorola: {
        code: "status_cadastro_venda_motorola",
        componentName: rootGetters["User/isAdminUser"] ? "SaleRegisterMotorola" : "Endorsement",
      },
      // Pendenciamento
      status_pendenciado: {
        code: "status_pendenciado",
        componentName: "FlowPending",
      },

      // status da TSHIELD
      aguardando_analise_documentacao: {
        code: "aguardando_analise_documentacao",
        componentName: "ExternalDocumentUpload",
      },
      documentacao_envio_tshield: {
        code: "documentacao_envio_tshield",
        componentName: "ExternalDocumentUpload",
      },
      // Blufin
      blufin_simulation: {
        code: "blufin_simulation",
        componentName: "BlufinSimulation",
      },
      status_simulacao: {
        componentName: "SimulationGen",
        code: "status_simulacao",
        shortTitle: "Simulação",
      },
      status_autenticacao: {
        componentName: "BlankComponent",
        code: "status_autenticacao",
        shortTitle: "Autenticação",
      },
      status_autorizacao_credito: {
        componentName: "CreditAuthorization",
        code: "status_autorizacao_credito",
      },
      unmapped_status: {
        code: "unmapped_status",
        componentName: "UnmappedStatusScreen",
      },
      selecao_de_valores: {
        code: "selecao_de_valores",
        componentName: "ValueSelections",
      },
      status_pre_proposal: {
        code: "status_pre_proposal",
        componentName: "PreProsal",
      },
      billing: {
        code: "billing",
        componentName: "Billing",
      },
      accept_terms: {
        code: "accept_terms",
        componentName: "AcceptTerms",
      },
      loan_reason: {
        code: "loan_reason",
        componentName: "LoanReason",
      },
      scr_query_authorization: {
        code: "scr_query_authorization",
        componentName: "BlankComponent",
      },
      // esse status não ta sendo usado no momento
      // insurance_selection: {
      //   code: "insurance_selection",
      //   componentName: "InsuranceSelection",
      // },
      selecao_de_valores_v2: {
        code: "selecao_de_valores_v2",
        componentName: "SolicitedValue",
      },
      installment_selection: {
        code: "installment_selection",
        componentName: "InstallmentSelection",
      },
      status_send_payment_slips: {
        code: "status_send_payment_slips",
        componentName: "Billing",
      },
      status_loan_value_cards: {
        code: "status_loan_value_cards",
        componentName: "LoanValueCards",
      },
      status_installments_cards: {
        code: "status_installments_cards",
        componentName: "InstallmentsCards",
      },
      status_first_payment_selection: {
        code: "status_first_payment_selection",
        componentName: "FirstPaymentSelectionWrapper",
      },
      // esse status não ta sendo usado no momento
      // status_loan_value_range: {
      //   code: "status_loan_value_range",
      //   componentName: "LoanValueRange",
      // },
      status_installments_selection: {
        code: "status_installments_selection",
        componentName: "InstallmentSelection",
      },
      status_simulation_plans: {
        code: "status_simulation_plans",
        componentName: "SimulationPlans",
      },
      third_party_signature: {
        code: "third_party_signature",
        componentName: "SignatureThirdContract",
      },
      averbacao_rh: {
        componentName: (function () {
          const isAdminUser = rootGetters["User/isAdminUser"];
          return isAdminUser ? "AverbacaoRH" : "Analyze";
        })(),
        code: "averbacao_rh",
      },
    };
  },
  isMotorola: (state) => {
    return ProposalService.isProposalMotorola(state.proposal);
  },
  isFGTS: (state) => {
    return ProposalService.isProposalMotorola(state.proposal);
  },
};
export default getters;
